
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Link } from 'gatsby'

import { userAtom } from 'utils/atoms'
import Meta from 'layout/Meta'
import Spinner from 'utils/Spinner'
import Symbol from 'utils/Symbol'


export default function HomePage() {

	const { isAuth, isSigningIn } = useRecoilValue(userAtom)
	
	return <main id='homeMain'>

		<Meta />

		<header id='homeHeader'>
			<h1>Talk anonymously with people you know.</h1>
			<p>Create an invite-only chatroom where all messages are anonymous.</p>
			<p><em>Optional:</em> set your room to require a sign in with a Facebook profile so that everybody knows who is in the room but nobody knows who wrote what.</p>
		</header>

		<p id='homeActions'>
			{ isAuth
				?	<Link to='/chat/' className='fat button primary'>
						Go to Chat <Symbol id='rightArrow' />
					</Link>
				:	isSigningIn
				?	<Spinner>Signing in</Spinner>
				:	<Link to='/signin/' className='button primary'>Sign in</Link>
			}
			<Link to='/create/' className='fat button'>
				Start a chatroom
			</Link>
		</p>

		<p>
			
		</p>

		{/* <div>
			<p>Do you have an invite code?</p>
			<Link to='/join' className='big'>
				Join a chatroom
			</Link>
		</div> */}

	</main>
}
